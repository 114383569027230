<template>
  <div style="width: 100%; padding: 10px">
    <h1>{{ $options.name }}</h1>
    <EMasterDetailView
      container_name="recipes"
      header_attribute="name"
      description_attribute="duration"
      image_attribute="picture_url"
      :detail_view_content="detail_view_content"
      :management_view_content="management_view_content"
    ></EMasterDetailView>
  </div>
</template>

<script>
export default {
  name: "Recipes",
  data() {
    return {
      // Define here the custom attributes and container
      detail_view_content: [
        {
          name: this.$t("recipes.duration"),
          value: "duration",
        },
        {
          name: this.$t("recipes.type"),
          value: "type",
        },
        {
          name: this.$t("drinks.ingredients"),
          value: "ingredients",
          formatter: this.formatIngredient,
        },
      ],

      management_view_content: [
        {
          name: "name",
          label: "Name",
          type: "text",
          required: true
        },
        {
          name: "type",
          label: this.$t("recipes.type"),
          type: "select",
          options: [
            "Apero",
            "Vorspeise",
            "Suppe",
            "Salat",
            "Hauptgang",
            "Dessert"
          ]
        },
        {
          name: "duration",
          label: this.$t("recipes.duration"),
          type: "text",
          required: false
        },
        {
          name: "picture_url",
          label: this.$t("elements.image"),
          type: "file"
        },
        {
          name: "ingredients",
          label: this.$t("recipes.ingredients"),
          type: "textarea"
        },
        {
          name: "process",
          label: this.$t("recipes.process"),
          type: "textarea"
        }
      ]
    };
  },
  methods: {
    formatIngredient(ingredients) {
      var ingredientsArray = ingredients.split(",");
      return ingredientsArray.join("<br>");
    },
  },
};
</script>

<style>
.detail_view {
  margin: 10px;
}
</style>